<template>
  <div>
    <div class="open-in-brower" v-if="isWechat() && show">
      <van-image :src="openInBrower"></van-image>
    </div>
    <slot v-else></slot>
  </div>
</template>
<script>
import openInBrower from '@/assets/images/open_in_brower.jpg'
import { isWechat } from '@/utils'
import { Image } from 'vant'

export default {
  name: 'OpenInBrower',
  components: {
    'van-image': Image,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      openInBrower,
    }
  },
  methods: {
    isWechat,
  },
}
</script>
<style lang="less" scoped>
.open-in-brower {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: #fff;
  z-index: 3000;
}
</style>
