<template>
  <div class="download">
    <open-in-brower-layout>
      <div>
        <div class="down-wrap">
          <div class="game-info">
            <p class="info-item">
              <span>5.0分</span>&nbsp;&nbsp;<span>{{
                download[systemType].packageSize
              }}</span
              >&nbsp;&nbsp;<span>版本：1.8.0</span>
            </p>
            <p class="info-item">
              <span>更新时间：2023-9-16 13:17:24</span>
            </p>
          </div>
          <div class="download-btn">
            <van-button
              block
              type="primary"
              @click="downloadHandle"
              v-if="!downloadStatus"
              >立即下载</van-button
            >
            <div class="download-notice font-red" v-else>
              <p>正在下载中...</p>
              <p v-if="systemType === 'ios'">请按Home键查看</p>
            </div>
          </div>
        </div>

        <div class="kefu">
          <p class="info-item">
            <span>下载中遇到问题请联系客服</span>
          </p>
          <p class="info-item">
            <span>客服微信：{{ wechatId }}</span>
          </p>
          <!-- <p class="info-item">
            <span>客服QQ：1057768840</span>
          </p> -->
        </div>

        <van-popup v-model="downloadKf" round closeable>
          <div style="padding: 10px" class="flex flex-column align-center">
            <div style="text-align:center;font-size: 12px;color: red;">
              <span>iPhone用户请添加客服下载</span>
            </div>
            <van-image
              :src="QunQr"
              style="width: 120px; height: 120px"
            ></van-image>
            <div style="text-align:center;font-size: 12px;x">
              <span>长按二维码识别</span>
            </div>
          </div>
        </van-popup>
      </div>
    </open-in-brower-layout>
  </div>
</template>
<script>
import { Button, Image, Popup } from "vant";
import { isIos } from "@/utils";
import { mapActions, mapState } from "vuex";
import OpenInBrowerLayout from "@/components/OpenInBrower";

export default {
  name: "TlbbDownload",
  components: {
    "van-button": Button,
    "van-image": Image,
    "van-popup": Popup,
    "open-in-brower-layout": OpenInBrowerLayout
  },
  data() {
    return {
      QunQr: "",
      systemType: "andriod",
      downloadStatus: false,
      download: {
        andriod: {
          packageSize: "1.731G"
        },
        ios: {
          packageSize: "1.497G"
        }
      },
      downloadKf: false
    };
  },
  computed: {
    ...mapState({
      wechatId: state => state.setting.setting.wechatId,
      groupQrUrl: state => state.settting.setting.groupQrUrl
    })
  },
  mounted() {
    if (this.isIos()) {
      this.systemType = "ios";
    } else {
      this.systemType = "andriod";
    }
  },
  methods: {
    ...mapActions("reword", ["getDownloadUrl"]),
    isIos,
    async downloadHandle() {
      this.downloadStatus = true;
      if (this.systemType === "ios") {
        let downloadUrl = `itms-services://?action=download-manifest&url=https://${window.location.hostname}/api/tlbb/download/ios`;
        window.location.href = downloadUrl;

        // 提示联系客服下载打开
        // this.downloadKf = true
        // this.downloadStatus = false
      } else {
        let { data } = await this.getDownloadUrl();
        window.location.href = data.url;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.info-item {
  padding: 5px;
}
.down-wrap {
  .download-btn {
    margin-top: 50px;
    padding: 0 40px;
    .download-notice {
      text-align: center;
    }
  }

  .game-info {
    text-align: center;
  }

  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
}

.kefu {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #aaa;
}
</style>
